.drawer {
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &__header,
  &__footer {
    @include flex(center, space-between);

    position: -webkit-sticky;
    position: sticky;
    z-index: 10;
    height: 100px;
    padding: 0 20px;
    background-color: $txt-silver;
  }

  &__body {
    min-height: calc(100vh - 200px);
  }

  &__header {
    top: 0;
    border-bottom: 1px solid #eee;

    &__subtitle {
      font-size: 0.9rem;
      color: $txt-second-color;
    }

    &__close {
      @include flex(center, center);

      width: 40px;
      height: 40px;
      box-shadow: $box-shadow-item;
      background-color: $txt-white;
      border-radius: 50%;

      .icon {
        font-size: 1.2rem;
        color: $main-color;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__footer {
    bottom: 0;
    border-top: 1px solid #eee;
    gap: 20px;

    .btn {
      @include flex(center, center);

      width: 50%;
      height: 50px;
      font-size: 0.9rem;
      background-color: #ddd;
      border: none;
      border-radius: 20px;
      color: $txt-second-color;

      &:hover {
        cursor: pointer;
        background-color: $txt-white;
      }

      &:focus,
      &:active {
        outline: none;
        border: none;
      }
    }

    .btn-update {
      background-color: $main-color;
      color: $txt-white;

      &:hover {
        cursor: pointer;
        background-color: #01b6fe;
        color: $txt-white;
      }
    }

    .btn-disabled {
      background-color: #ccc;
      color: $txt-second-color;

      &:hover {
        cursor: not-allowed;
        background-color: #ccc;
      }
    }
  }
}
