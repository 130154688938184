@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Montez&family=Montserrat:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

html {
  font-size: 1rem;
}

body {
  font-family: "Montserra", sans-serif;
  color: $txt-main-color;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  // color: $main-color;
  color: $txt-main-color;
  cursor: pointer;
}

@each $var in $numbers {
  .grid-col-#{$var} {
    grid-template-columns: repeat($var, 1fr);
  }
}

@each $var in $numbers {
  .grid-col-md-#{$var} {
    @include tablet {
      grid-template-columns: repeat($var, 1fr);
    }
  }
}

@each $var in $numbers {
  .grid-col-sm-#{$var} {
    @include mobile {
      grid-template-columns: repeat($var, 1fr);
    }
  }
}

.container {
  width: 100%;
  max-width: 1620px;
  padding: 0;
  margin: auto;

  @include tablet {
    padding: 0 20px;
  }

  @include mobile {
    padding: 0 10px;
  }

}

.layout {
  max-width: 1920px;
}

.main {
  margin-top: calc(#{$header-height} + 20px);
  margin-bottom: 2rem;
  min-height: 100vh;

  @include tablet {
    margin-top: calc(#{$header-tablet-height} + 20px);
  }

  @include mobile {
    margin-top: calc(#{$header-mobile-height} + 10px);
  }
}

.grid {
  display: grid;
}

.rate {
  color: $rating;
  font-size: 1.2rem;
  right: 10px;
  margin-right: 5px;
}

.flash-message {
  @include flex(center, center);

  position: absolute;
  top: 20px;
  right: 600px;
  min-height: 60px;
  width: 300px;
  box-shadow: 0px 5px 8px 3px rgba(130,130,130,1);
  -webkit-box-shadow: 0px 5px 8px 3px rgba(130,130,130,1);
  -moz-box-shadow: 0px 5px 8px 3px rgba(130,130,130,1);
  background-color: $txt-white;
  z-index: 99;
  border-radius: 10px;
}
