.login {
  height: 100vh;
  @include flex(center, center);

  &__card {
    flex-direction: column;

    min-height: 300px;
    width: 400px;
    border: 1px solid $txt-silver;
    border-radius: 10px;
    box-shadow: $box-shadow;
  }

  &__main {
    &__body {
      &__input {
        width: 100%;
        height: auto;
        margin: 10px 0;
        border-radius: 5px;
        flex-direction: column;

        @include flex(center, center);

        input {
          width: 80%;
          height: 40px;
          margin-bottom: 30px;
          padding: 0 10px;
          outline: none;
          border-radius: 5px;
          border: 1px solid $txt-gray;

          &::placeholder {
            color: $txt-gray;
            text-transform: capitalize;
          }

          &:focus {
            outline: 1px solid $txt-gray;
            box-shadow: $box-shadow-item;
            border: 1px solid $txt-gray;
          }
        }
      }
    }
  }

  &__header {
    @include flex(center, center);

    width: 100%;
    height: 100px;
    font-family: $font-design;
    flex-direction: column;
    font-size: 1.2rem;

    .text-header {
      font-size: large;
    }
  }

  &__button__login {
    @include flex(flex-end, center);

    width: 100%;
    height: 100%;
    margin-bottom: 50px;

    .btn-login {
      @include flex(center, center);

      width: 80%;
      height: 40px;
      background-color: $main-color;
      border-radius: 5px;
      color: $txt-white;

      &:hover {
        cursor: pointer;
        background-color: #01b6fe;
      }
    }

    .text-register {
      @include flex(center, center);
      padding-left: 10px;
      font-weight: bold;
      padding: 20;

      &:hover {
        background-color: rgb(216, 214, 214);
        border-radius: 15;
        padding: 20;
      }
    }
  }
}
