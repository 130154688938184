.modal {
    @include flex(center, space-between);
  
    flex-direction: column;
  
    &__info {
      @include flex(center, center);
  
      padding: 40px 80px;
      gap: 10px;
      flex-direction: column;
  
      .icon {
        font-size: 2rem;
        color: #f50057;
      }
  
      &__title {
        text-align: center;
        font-size: 1.2rem;
  
        .color {
          color: #f50057;
          font-weight: 600;
        }
      }
  
      &__subtitle {
        text-align: center;
        font-size: 0.9rem;
        color: $txt-second-color;
      }
    }
    &__input {
      width: 100%;
      height: 100px;
      padding: 0 30px;
  
      @include flex(flex-start, flex-start);
  
      .title {
        flex: 1;
        // color: $txt-second-color;
        font-size: 0.9rem;
        margin-top: 5px;
      }
  
      .content {
        @include flex(flex-start, center);
  
        flex: 2;
        margin-left: 20px;
        margin: 10px 0;
        flex-direction: column;
  
        &__container {
          @include flex(center, center);
  
          height: 150px;
          width: 100%;
          border: 2px dashed #ccc;
          border-radius: 10px;
          flex-direction: column;
  
          &:hover {
            cursor: pointer;
          }
  
          & * {
            padding: 4px 0;
          }
        }
      }
  
      .input-container {
        width: 100%;
        min-height: 50px;
  
        input {
          width: 100%;
          min-height: 50px;
          padding: 0 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 0.8rem;
          background-color: $txt-silver;
  
          &:focus {
            outline: none;
            border: 1px solid $txt-gray;
            background-color: $txt-white;
          }
        }
      }
    }
  
    &__buttons {
      @include flex(center, center);
      gap: 40px;
      position: -webkit-sticky;
      position: fixed;
      z-index: 10;
      height: 90px;
      width: 100%;
      bottom: 0;
      background-color: $txt-silver;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
  
      .btn {
        @include flex(center, center);
  
        width: 200px;
        height: 50px;
        font-size: 0.9rem;
        border: none;
        border-radius: 20px;
        background-color: #ddd;
        color: $txt-second-color;
  
        &:hover {
          cursor: pointer;
          background-color: $txt-white;
        }
  
        &:focus,
        &:active {
          outline: none;
          border: none;
        }
      }
  
      .btn-delete {
        background-color: $main-color;
        color: $txt-white;
  
        &:hover {
          cursor: pointer;
          background-color: #01b6fe;
          color: $txt-white;
        }
      }
    }
  }