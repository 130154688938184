$main-bg: #fff;
$main-color: #35c5fe;

$txt-main-color: #1d1d1d;
$txt-second-color: #5c6f70;
$txt-white: #fff;
$txt-gray: #aaa;
$txt-silver: #f7f7f7;
$txt-blue: #2855F4;

$btn-main-bg: $main-color;
$btn-main-color: #fff;

$header-height: 90px;
$header-tablet-height: 70px;
$header-mobile-height: 40px;
$header-shrink-height: 60px;
$rating: #f7b500;

$box-shadow: rgba(149, 157, 165, 0.7) 0px 8px 20px;
$box-shadow-item: 0px 3px 5px -1px rgba(149, 157, 165, 0.7);

$box-shadow-nav: rgba(34, 144, 253, 0.7) 1px 8px 10px;


$text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

$font-style: "Montez";

$font-design: "Bungee Spice";

$mobile-width: 600px;
$tablet-width: 1024px;

$numbers: (0, 1, 2, 3, 4, 5, 6, 7, 8);
